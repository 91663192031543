/**
 * @ Author: 刘迪宜
 * @ Description: vcard管理后台
 * 后台正式：https://vcard123service.51vcard.com/#/login
 * 后台测试：https://test.webadmin.51vcard.com/#/login
 */
let net_host = "https://api.51vcard.com" // 正式
// 自动切换地址
const host = window.location.host
const testArr = [
  "192.168.31.241:8030",
  "localhost:8030",
  "test.webadmin.51vcard.com"
]
if (testArr.indexOf(host) !== -1) {
  net_host = "https://testapi.51vcard.com"
} else {
  net_host = "https://api.51vcard.com"
  window.console.log = () => {}
}

const NetAddr = {
  sysHost: net_host,
  // 系统管理
  // 登录
  adminLogin: `${net_host}/admin-api/sysUser/login`,
  // 注册用户
  adminRegister: `${net_host}/admin-api/sysUser/register`,
  // 发送验证码
  getVerifyCode: `${net_host}/admin-api/sysUser/sendEmailCode`,
  // 角色管理 分页查询
  roleControlList: `${net_host}/admin-api/sysRole/listPage`,
  // 角色管理 新增或修改
  roleControlAdd: `${net_host}/admin-api/sysRole/insertOrUpdate`,
  // 角色管理 删除
  roleControlDel: `${net_host}/admin-api/sysRole/delete`,
  // 角色管理 查询所有角色
  roleControlAll: `${net_host}/admin-api/sysRole/listAll`,
  // 角色管理 查询角色权限
  roleControlAuthList: `${net_host}/admin-api/sysRole/listByRoleId`,
  // 角色管理 更新角色权限
  roleControlAuthAdd: `${net_host}/admin-api/sysRole/updateRoleMenuList`,
  // 账户管理 分页查询
  accountControlList: `${net_host}/admin-api/sysUser/listPage`,
  // 账户管理 新增或修改
  accountControlAdd: `${net_host}/admin-api/sysUser/insertOrUpdate`,
  // 账户管理 删除
  accountControlDel: `${net_host}/admin-api/sysUser/delete`,
  // 账户管理 更新角色
  accountControlUpdate: `${net_host}/admin-api/sysUser/updateUserRole`,
  // 菜单管理 分页查询
  menuControlList: `${net_host}/admin-api/sysMenu/listPage`,
  // 菜单管理 查询用户菜单（树形）
  menuControlListAll: `${net_host}/admin-api/sysMenu/listUserMenuForTree`,
  // 菜单管理 新增或修改
  menuControlAdd: `${net_host}/admin-api/sysMenu/insertOrUpdate`,
  // 菜单管理 删除
  menuControlDel: `${net_host}/admin-api/sysMenu/delete`,
  // 系统参数管理 分页查询
  systemParamList: `${net_host}/admin-api/sysParam/listPage`,
  // 系统参数管理 新增或修改
  systemParamAdd: `${net_host}/admin-api/sysParam/insertOrUpdate`,
  // 系统参数管理 删除
  systemParamDel: `${net_host}/admin-api/sysParam/delete`,
  // 操作记录 分页查询
  systemRecordList: `${net_host}/admin-api/adminOperationRecord/listPage`,
  // 用户管理
  // 用户明细 分页查询
  userDetailList: `${net_host}/admin-api/user/sysUser/listPage`,
  // 用户明细 用户总数信息
  userDetailAll: `${net_host}/admin-api/user/sysUser/sysUserTotal`,
  // 用户明细 分页查询下线信息
  userDetailDown: `${net_host}/admin-api/user/sysUser/listDownUser`,
  // 用户明细 导出数据
  userDetailExport: `${net_host}/admin-api/user/sysUser/export`,
  // 用户明细 状态修改
  userDetailUpdate: `${net_host}/admin-api/user/sysUser/updateUserState`,
  // 卡管理
  // 卡明细 分页查询
  cardDetailList: `${net_host}/admin-api/user/userCard/listPage`,
  // 卡明细 卡总数信息
  cardDetailAll: `${net_host}/admin-api/user/userCard/userCardTotal`,
  // 卡明细 导出数据
  cardDetailExport: `${net_host}/admin-api/user/userCard/export`,
  // 卡消费明细 分页查询
  cardSpendingList: `${net_host}/admin-api/user/userCardTransactionBill/listPage`,
  // 卡消费明细 卡账单总数信息
  cardSpendingAll: `${net_host}/admin-api/user/userCardTransactionBill/userCardBillTotal`,
  // 卡消费明细 导出数据
  cardSpendingExport: `${net_host}/admin-api/user/userCardTransactionBill/export`,
  // 卡充值明细 查询所有
  cardTopupDetailList: `${net_host}/admin-api/user/userCardRecharge/listPage`,
  // 卡充值明细 充值总数信息
  cardTopupDetailAll: `${net_host}/admin-api/user/userCardRecharge/total`,
  // 卡消费明细 导出数据
  cardTopupDetailExport: `${net_host}/admin-api/user/userCardRecharge/export`,
  // 卡充值配置 查询
  cardTopupList: `${net_host}/admin-api/user/userCardRecharge/getRechargeLimit`,
  // 卡充值配置 修改充值限制
  cardTopupAdd: `${net_host}/admin-api/user/userCardRecharge/updateRechargeLimit`,
  // 卡权益配置 查询所有
  cardEquityConfigList: `${net_host}/admin-api/user/cardLevelConfig/listPage`,
  // 卡权益配置 新增或修改
  cardEquityConfigAdd: `${net_host}/admin-api/user/cardLevelConfig/saveOrUpdate`,
  // 卡权益配置 卡等级编号 查询详情
  cardEquityClassList: `${net_host}/admin-api/user/cardLevelProductCodeConfig/listPage`,
  // 卡权益配置 卡等级编号 新增或修改
  cardEquityClassAdd: `${net_host}/admin-api/user/cardLevelProductCodeConfig/saveOrUpdate`,
  // 卡权益配置 卡等级编号 删除
  cardEquityClassDel: `${net_host}/admin-api/user/cardLevelProductCodeConfig/delete`,
  // 分销管理
  // 邀请明细 分页查询
  invitationDetailList: `${net_host}/admin-api/distribution/distributionRelation/listPage`,
  // 邀请明细 关系总数信息
  invitationDetailAll: `${net_host}/admin-api/distribution/distributionRelation/total`,
  // 邀请明细 导出数据
  invitationDetailExport: `${net_host}/admin-api/distribution/distributionRelation/export`,
  // 分佣明细 分页查询
  giveawayDetailList: `${net_host}/admin-api/distribution/distributionCommission/listPage`,
  // 分佣明细 分销关系总数信息
  giveawayDetailAll: `${net_host}/admin-api/distribution/distributionCommission/total`,
  // 分佣明细 导出数据
  giveawayDetailExport: `${net_host}/admin-api/distribution/distributionCommission/export`,
  // 分佣比例配置 分销代理用户一级分成比例
  giveawayRatioFirst: `${net_host}/admin-api/distribution/distributionUser/agentUserOneRate`,
  // 分佣比例配置 分页查询
  giveawayRatioList: `${net_host}/admin-api/distribution/distributionUser/listPage`,
  // 分佣比例配置 修改代理人佣金比例
  giveawayRatioAddAgent: `${net_host}/admin-api/distribution/distributionUser/updateAgentUser`,
  // 分佣比例配置 修改合伙人佣金比例
  giveawayRatioAddPartner: `${net_host}/admin-api/distribution/distributionUser/updatePartnerUser`,
  // 合伙人配置 分页查询
  partnerConfigList: `${net_host}/admin-api/distribution/distributionPartner/listPage`,
  // 合伙人配置 新增或修改
  partnerConfigAdd: `${net_host}/admin-api/distribution/distributionPartner/insertOrUpdate`,
  // 合伙人配置 删除
  partnerConfigDel: `${net_host}/admin-api/distribution/distributionPartner/delete`,
  // 黑卡资格名单 分页查询
  blackCardList: `${net_host}/admin-api/user/userActivityQualification/listPage`,
  // 黑卡资格名单 新增或修改
  blackCardAdd: `${net_host}/admin-api/user/userActivityQualification/saveOrUpdate`,
  // 黑卡资格名单 导出数据
  blackCardExport: `${net_host}/admin-api/user/userActivityQualification/export`,
  // 钱包管理
  // 充币明细 分页查询
  coinTopupDetailList: `${net_host}/admin-api/cobo/usdtRechargeRecord/listPage`,
  // 充币明细 充币统计
  coinTopupDetailAll: `${net_host}/admin-api/cobo/usdtRechargeRecord/getUsdtRechargeCount`,
  // 充币明细 导出数据
  coinTopupDetailExport: `${net_host}/admin-api/cobo/usdtRechargeRecord/export`,
  // 兑币明细 分页查询
  coinExchangeDetailList: `${net_host}/admin-api/wallet/exchangeRecord/listPage`,
  // 兑币明细 记录统计
  coinExchangeDetailAll: `${net_host}/admin-api/wallet/exchangeRecord/getCountExchangeRecord`,
  // 兑币明细 导出数据
  coinExchangeDetailExport: `${net_host}/admin-api/wallet/exchangeRecord/export`,
  // 提币明细 分页查询
  withdrawDetailList: `${net_host}/admin-api/cobo/usdtWithdrawRecord/listPage`,
  // 提币明细 提币统计
  withdrawDetailAll: `${net_host}/admin-api/cobo/usdtWithdrawRecord/getUsdtWithdrawCount`,
  // 提币明细 导出数据
  withdrawDetailExport: `${net_host}/admin-api/cobo/usdtWithdrawRecord/export`,
  // 兑率/手续费配置 分页查询
  coinExchangeRateList: `${net_host}/admin-api/wallet/exchangeRate/listPage`,
  // 兑率/手续费配置 新增或修改
  coinExchangeRateAdd: `${net_host}/admin-api/wallet/exchangeRate/insertOrUpdate`,
  // 兑率/手续费配置 删除
  coinExchangeRateDel: `${net_host}/admin-api/wallet/exchangeRate/delete`,
  // 币的主网配置 分页查询
  coinNetworkList: `${net_host}/admin-api/cobo/mainNetwork/listPage`,
  // 币的主网配置 新增或修改
  coinNetworkAdd: `${net_host}/admin-api/cobo/mainNetwork/insertOrUpdate`,
  // 币的主网配置 删除
  coinNetworkDel: `${net_host}/admin-api/cobo/mainNetwork/delete`,
  // PayPal转账 分页查询
  transferMoneyList: `${net_host}/admin-api/wallet/thirdTransferRecord/listPage`,
  // PayPal转账 新增或修改
  transferMoneyAdd: `${net_host}/admin-api/wallet/thirdTransferRecord/insertOrUpdate`,
  // PayPal转账 删除
  transferMoneyDel: `${net_host}/admin-api/wallet/thirdTransferRecord/delete`,
  // PayPal转账 转账审核
  transferMoneyAudit: `${net_host}/admin-api/wallet/thirdTransferRecord/auditTransfer`,
  // PayPal转账 导出数据
  transferMoneyExport: `${net_host}/admin-api/wallet/thirdTransferRecord/export`,
  // 支付宝转账
  // 支付宝转账 分页查询
  alipayMoneyList: `${net_host}/admin-api/wallet/gsalaryRemittanceOrderRecord/listPage`,
  // 支付宝转账 导出数据
  alipayMoneyExport: `${net_host}/admin-api/wallet/gsalaryRemittanceOrderRecord/export`,
  // 转账功能开通明细
  // 转账功能开通明细 分页查询
  transferOpenDetailsList: `${net_host}/admin-api/wallet/thirdTransfer/listPage`,
  // 转账管理
  // 转账管理 分页查询
  transferConfigList: `${net_host}/admin-api/wallet/adminTransferRecord/listPage`,
  // 转账管理 导出数据
  transferConfigExport: `${net_host}/admin-api/wallet/adminTransferRecord/export`,
  // 转账管理 获取文件数据
  transferConfigFileData: `${net_host}/admin-api/wallet/adminTransferRecord/convertFileData`,
  // 转账管理 开始转账
  transferConfigInitTransfer: `${net_host}/admin-api/wallet/adminTransferRecord/initTransfer`,
  // 转账管理 重新转账
  transferConfigReTransfer: `${net_host}/admin-api/wallet/adminTransferRecord/reTransfer`,
  // 用户余额明细 分页查询
  balanceDetailList: `${net_host}/admin-api/user/wallet/listPage`,
  // 用户余额明细 导出数据
  balanceDetailExport: `${net_host}/admin-api/user/wallet/export`,
  // 用户余额明细 用户钱包扣款
  balanceDetailDeduction: `${net_host}/admin-api/user/wallet/deduction`,
  // 用户余额明细 分页查询USDT钱包记录
  balanceDetailUSDTList: `${net_host}/admin-api/wallet/usdtWallet/listPage`,
  // 用户余额明细 用户USDT钱包记录导出
  balanceDetailUSDTExport: `${net_host}/admin-api/wallet/usdtWallet/export`,
  // 用户余额明细 分页查询法币钱包记录
  balanceDetailEURList: `${net_host}/admin-api/wallet/currencyWallet/listPage`,
  // 用户余额明细 用户法币钱包记录导出
  balanceDetailEURExport: `${net_host}/admin-api/wallet/currencyWallet/export`,
  // 运营管理
  // 轮播图 分页查询
  bannerConfigList: `${net_host}/admin-api/config/pageBroadcastMessage/listPage`,
  // 轮播图 根据id查找
  bannerConfigById: `${net_host}/admin-api/config/pageBroadcastMessage/getById`,
  // 轮播图 新增或修改
  bannerConfigAdd: `${net_host}/admin-api/config/pageBroadcastMessage/saveOrUpdateByJson`,
  // 轮播图 批量删除
  bannerConfigDel: `${net_host}/admin-api/config/pageBroadcastMessage/removeByIds`,

  // 独立功能
  // 验证码 codeType:9,email
  creatVerifyCode: `${net_host}/api/user/creatUserCode`,
  // 上传图片、文件
  upload: `${net_host}/commonAdmin/uploadAliYun/upload`
}

export default NetAddr
